import FullscreenOverlay from './FullscreenOverlay';
import WistiaVideoPlayer from './WistiaVideoPlayer';

const INLINE_VIDEO_PLAYER_ID = 'wistia_video_frontpage_hero_inline';
const OVERLAY_VIDEO_PLAYER_ID = 'wistia_video_frontpage_hero_overlay';
const OVERLAY_SELECTOR = '[data-video-hero-overlay]';
const POST_ROLL_SELECTOR = '[data-overlay-hero-video-post-roll]';
const OVERLAY_VIDEO_TRIGGER_SELECTOR = '[data-overlay-hero-video-trigger]';
const OVERLAY_VIDEO_CONTAINER_SELECTOR = '[data-overlay-hero-video-container]';
const INLINE_VIDEO_TRIGGER_SELECTOR = '[data-inline-video-player-trigger]';
const INLINE_VIDEO_CONTAINER_SELECTOR = '[data-hero-video-inline-player-container]';
const INLINE_VIDEO_ACTIVE_WATCHER_SELECTOR = '[data-hero-video-inline-player-status-watcher]';

export default class VideoHero {
    constructor(element) {
        this.element = element;

        const playerId = this.element.getAttribute('data-player-id');
        this.inlinePlayer = this._setupInlinePlayer();
        this.overlayPlayer = this._setupOverlayPlayer(playerId);
        this.overlay = this._setupOverlay();

        this.element
            .querySelector(OVERLAY_VIDEO_TRIGGER_SELECTOR)
            .addEventListener('click', () => this._showOverlayPlayer());
    }

    _setupOverlay() {
        return new FullscreenOverlay({
            element: this.element.querySelector(OVERLAY_SELECTOR),
            options: {
                onOpen: () => {
                    this.overlayPlayer.play();
                },
                onClose: () => {
                    this.overlayPlayer.pause();
                    this._showOverlayPlayer();
                },
            },
        });
    }

    _setupOverlayPlayer(playerId) {
        return new WistiaVideoPlayer({
            id: playerId ? playerId : OVERLAY_VIDEO_PLAYER_ID,
            triggers: [this.element.querySelector(OVERLAY_VIDEO_TRIGGER_SELECTOR)],
            options: {
                onEnd: () => this._attemptToShowPostRollContent(),
                onFullscreenLeave: () => this._handleFullscreenLeave(),
            },
        });
    }

    _setupInlinePlayer() {
        const activeWatchers = this.element.querySelectorAll(INLINE_VIDEO_ACTIVE_WATCHER_SELECTOR);

        return new WistiaVideoPlayer({
            id: INLINE_VIDEO_PLAYER_ID,
            triggers: this.element.querySelectorAll(INLINE_VIDEO_TRIGGER_SELECTOR),
            options: {
                onPlay: () => {
                    for (let watcher of activeWatchers) {
                        watcher.setAttribute('data-wistia-player-active', true);
                    }
                },
                onPause: () => {
                    for (let watcher of activeWatchers) {
                        watcher.removeAttribute('data-wistia-player-active');
                    }
                },
                onReady: () => {
                    this.element
                        .querySelector(INLINE_VIDEO_CONTAINER_SELECTOR)
                        .setAttribute('data-wistia-player-ready', true);
                },
            },
        });
    }

    _showOverlayPlayer() {
        this.element.querySelector(OVERLAY_VIDEO_CONTAINER_SELECTOR).removeAttribute('data-hidden');
        this.element.querySelector(POST_ROLL_SELECTOR).setAttribute('data-hidden', '');
    }

    _handleFullscreenLeave() {
        if (this.overlayPlayer.state() === 'ended') {
            this._attemptToShowPostRollContent();
        }
    }

    _attemptToShowPostRollContent() {
        // Don't show post-roll content if fullscreen is active due to browser
        // issues hiding the player while in fullscreen mode
        if (!this.overlayPlayer.isFullscreen) {
            this._showPostRollContent();
        }
    }

    _showPostRollContent() {
        this.element.querySelector(POST_ROLL_SELECTOR).removeAttribute('data-hidden');
        this.element
            .querySelector(OVERLAY_VIDEO_CONTAINER_SELECTOR)
            .setAttribute('data-hidden', '');
    }

    static create(selector = '[data-video-hero]') {
        const heros = document.querySelectorAll(selector);

        heros.forEach(hero => {
            if (hero) {
                new VideoHero(hero);
            }
        });
    }
}
