import SlideCarousel from './components/slide-carousel';
import VideoHero from './components/VideoHero';
import createScrollableElements, {
    scrollOnHover,
    centerMiddleElementInScrollableContainer,
} from './components/scrollable';
import TwoImageSwap from './components/TwoImageSwap';
import { trackEvent, trackLink } from '@99designs/common/utils/platform';
import StickyBar from './components/StickyBar';
import NumberCounter from './components/NumberCounter';

document.addEventListener('DOMContentLoaded', () => {
    initScrollables();

    SlideCarousel.create({ stepClickEventMessage: 'Clicked Carousel Trigger on Home Page' });

    VideoHero.create();

    const twoImageSwapElement = document.querySelector('[data-two-image-swap]');
    if (twoImageSwapElement) {
        new TwoImageSwap(twoImageSwapElement);
    }

    trackEvents();

    new StickyBar();

    new NumberCounter();
});

function initScrollables() {
    createScrollableElements();
    scrollOnHover('[data-scrollable]');
    centerMiddleElementInScrollableContainer('[data-scrollable="examples"]');
    // By default the design cards are not visible. This is to allow centering to occur
    // before they become visible. If centering is done while they are visible, it results
    // in a harsh "jump" while centering the content.
    showDesignCards();
}

function trackEvents() {
    trackWithLocation(
        '[data-homepage-cta]',
        'data-homepage-cta-location',
        'Clicked CTA on Home Page'
    );
    trackWithLocation(
        '[data-design-card-link]',
        'data-design-card-link-category',
        'Clicked Design Card on Home Page'
    );
    trackWithLocation(
        '[data-blog-link]',
        'data-blog-link-location',
        'Clicked Blog Link on Home Page'
    );
    trackWithLocation(
        '[data-designer-link]',
        'data-designer-link-designer',
        'Clicked Designer Link on Home Page'
    );

    Array.from(document.querySelectorAll('[data-two-image-swap-button]')).forEach(cta => {
        cta.addEventListener('click', () => {
            trackEvent('Clicked Two Image Swap button on Home Page');
        });
    });
}

function trackWithLocation(selector, locationAttr, message) {
    Array.from(document.querySelectorAll(selector)).forEach(item => {
        const location = item.getAttribute(locationAttr);
        trackLink(item, message, { cta_location: location });
    });
}

function showDesignCards() {
    const designCards = document.querySelectorAll('[data-design-card]');

    for (var i = 0; i < designCards.length; i++) {
        designCards[i].style.visibility = 'visible';
    }
}
