import { EventEmitter } from 'events';
import includes from 'lodash/includes';
import debounce from 'lodash/debounce';

export const sizes = {
    small: [0, 599],
    medium: [600, 899],
    normal: [900, 1299],
    large: [1300, 5000],
};

export const SIZES = {
    SMALL: 'small',
    MEDIUM: 'medium',
    NORMAL: 'normal',
    LARGE: 'large',
};

//
// A viewport utility for determining the current window size.
//
export default class Viewport extends EventEmitter {
    constructor() {
        super();
        this.size = null;
        this.handleResize();
        this.listen();
        this.width = window.innerWidth;
    }

    listen() {
        window.addEventListener(
            'resize',
            debounce(() => {
                // Don't trigger a change if the viewport idth hasn't actually changed.
                // Scrolling on iOS will trigger a resize.
                if (this.width !== window.innerWidth) {
                    this.handleResize();
                }
            }, 100)
        );
    }

    handleResize() {
        this.width = window.innerWidth;

        Object.keys(sizes).forEach(key => {
            var size = sizes[key];
            if (isViewportInRange(size[0], size[1])) {
                this.size = key;
            }
        });

        this.emit('change');
    }

    inSize(range = []) {
        return includes(range, this.size);
    }
}

//
// http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433
//
export function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

function isViewportInRange(min, max) {
    return window.innerWidth >= min && window.innerWidth <= max;
}

export function isSmallViewport() {
    return isViewportInRange(sizes.small[0], sizes.small[1]);
}

export function isMediumViewport() {
    return isViewportInRange(sizes.medium[0], sizes.medium[1]);
}

export function isNormalViewport() {
    return isViewportInRange(sizes.normal[0], sizes.normal[1]);
}

export function isLargeViewport() {
    return isViewportInRange(sizes.large[0], sizes.large[1]);
}
