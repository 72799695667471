export default class WistiaVideoPlayer {
    constructor({ id, triggers = [], options = {} }) {
        this.id = id;
        this.triggers = triggers;
        this.options = options;
        this._setup = this._setup.bind(this);

        // Setup Wistia player when available
        window._wq = window._wq || [];
        window._wq.push({
            [this.id]: this._setup,
        });
    }

    pause() {
        if (this.player) {
            this.player.pause();
        }
    }

    play() {
        if (this.player) {
            this.player.play();
        }
    }

    state() {
        if (this.player) {
            return this.player.state();
        }
    }

    _setup(player) {
        this.player = player;
        this.isFullscreen = false;

        this.player.bind('heightchange', height => {
            const fullscreenChange = this._detectFullscreenChange(height);

            if (fullscreenChange && fullscreenChange === 'enter') {
                if (this.options.onFullscreenEnter) {
                    this.options.onFullscreenEnter();
                }
            }

            if (fullscreenChange && fullscreenChange === 'leave') {
                if (this.options.onFullscreenLeave) {
                    this.options.onFullscreenLeave();
                }
            }
        });

        if (this.options.onPlay) {
            this.player.bind('play', this.options.onPlay);
        }

        if (this.options.onPause) {
            this.player.bind('pause', this.options.onPause);
        }

        if (this.options.onEnd) {
            this.player.bind('end', this.options.onEnd);
        }

        if (this.options.onReady) {
            this.options.onReady();
        }

        for (let trigger of this.triggers) {
            trigger.addEventListener('click', () => {
                this.play();
            });
        }
    }

    _detectFullscreenChange(height) {
        // The Wistia player seems to identify itself with a height of -1
        // compared to the screen-height
        const playerHeight = height + 1;

        if (playerHeight === window.screen.height) {
            this.isFullscreen = true;

            return 'enter';
        } else if (this.isFullscreen && playerHeight !== window.screen.height) {
            this.isFullscreen = false;

            return 'leave';
        }

        return null;
    }
}
