import { trackEvent } from '@99designs/common/utils/platform';

export default class TwoImageSwap {
    module: HTMLElement;
    button: HTMLElement | null;

    constructor(module: HTMLElement) {
        this.module = module;
        this.button = this.module.querySelector('[data-two-image-swap-toggle]');

        this.toggleElementsState = this.toggleElementsState.bind(this);
        this.initPlayButtonTracking = this.initPlayButtonTracking.bind(this);

        this.addEventListenersToButtons();
        this.initPlayButtonTracking();
    }

    addEventListenersToButtons() {
        if (this.button) {
            this.button.addEventListener('click', this.toggleElementsState);
        }
    }

    toggleElementsState() {
        const elements = this.module.querySelectorAll('[data-two-image-swap-toggle-state]');

        Array.from(elements).forEach(element => {
            element.getAttribute('data-active') !== null
                ? element.removeAttribute('data-active')
                : element.setAttribute('data-active', '');
        });
    }

    initPlayButtonTracking() {
        const playButton = this.module.querySelector('[data-two-image-swap-play-button]');

        if (playButton) {
            playButton.addEventListener('click', () => {
                trackEvent('Clicked Two Image Swap Play Button', {
                    location: playButton.getAttribute('data-two-image-swap-play-button-location'),
                });
            });
        }
    }
}
