import * as position from 'common/utils/elementPosition';

const ACTIVE_ATTRIBUTE = 'data-sticky-bar-active';
const HIDDEN_TILL_ACTIVE_ATTRIBUTE = 'data-sticky-bar-hidden-till-active';

export default class StickyBar {
    constructor(element = '[data-sticky-bar]') {
        this.stickyBar = document.querySelector(element);

        if (!this.stickyBar) {
            return;
        }

        this.initialPosition = this.getInitialPosition();

        this.handleOnScroll = this.handleOnScroll.bind(this);

        window.addEventListener('scroll', this.handleOnScroll);

        this.updatingInitialPosition = false;
        window.addEventListener('resize', () => {
            this.updateInitialPosition();

            // Required by IE11 and Safari to determine if the bar should be fixed after window resize
            this.handleOnScroll();
        });

        if (this.isFixable()) {
            this.fix();
        }
    }

    fix() {
        this.stickyBar.setAttribute(ACTIVE_ATTRIBUTE, true);
    }

    unfix() {
        this.stickyBar.removeAttribute(ACTIVE_ATTRIBUTE);
    }

    isHidden() {
        return getComputedStyle(this.stickyBar).display === 'none';
    }

    isHiddenTillActive() {
        return this.stickyBar.hasAttribute(HIDDEN_TILL_ACTIVE_ATTRIBUTE);
    }

    isActive() {
        return this.stickyBar.hasAttribute(ACTIVE_ATTRIBUTE);
    }

    isFixable() {
        return (
            this.isPassedScrollPoint() &&
            !this.isActive() &&
            (!this.isHidden() || this.isHiddenTillActive())
        );
    }

    getInitialPosition() {
        // The bar un-sticks too high above it's non-stuck position, minus 50 allows it to un-stick at an earlier position
        return position.distanceFromTop(this.stickyBar) + window.pageYOffset - 50;
    }

    isPassedScrollPoint() {
        return position.distanceFromTop(this.stickyBar) <= 0;
    }

    handleOnScroll() {
        if (!this.isHidden() || this.isHiddenTillActive()) {
            const distanceScrolled = window.pageYOffset;

            if (this.isPassedScrollPoint() && !this.isActive()) {
                this.fix();
            } else if (distanceScrolled <= this.initialPosition && this.isActive()) {
                this.unfix();
            }
        }
    }

    updateInitialPosition() {
        if (!this.updatingInitialPosition) {
            window.requestAnimationFrame(() => {
                // Reset position before calculation to determine where the sticky bar would
                // be before being fixed.
                this.stickyBar.style.position = 'relative';
                this.initialPosition = this.getInitialPosition();
                this.stickyBar.style.position = '';

                this.updatingInitialPosition = false;
            });
        }
    }
}
